#StatementTableRow{
    white-space: nowrap;
    position: absolute;
    left: 0;
    right: 0;
    font-size: 0.8rem;
}

#StatementTable .chart-title {
	text-indent: 1em;
}

#StatementTable th,
#StatementTable td {
	padding: 0.5rem 0.25rem;
}
